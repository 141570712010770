@use "../Variables.scss" as *;
@use "../Mixins.scss" as *;

.header-party-container {
    .header-party-wrapper {
        padding: 4px;
        background-color: $HeaderPartyBackground;
        @include flex-row-alignCenter();
        gap: 8px;
        border-radius: 99999px;
        .fill-item {
            @include width-height(30px, 30px);
            position: relative;
            .crown-svg {
                position: absolute;
                top: -13px;
                left: 5.5px;
                @include width-height(18px, 18px);
                color: gold;
            }
            &:hover {
                .party-kick {
                    opacity: 1;
                    @include width-height(100%, 100%);
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: auto;
                    cursor: pointer;
                    background-color: rgba(0, 0, 0, 0.24);
                    border-radius: 50%;
                }
            }
            .party-kick {
                position: absolute;
                opacity: 0;
            }
            
            .player-avatar {
                @include avatar-player(30px);
            }
        }

        .party-item {
            @include width-height(30px, 30px);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $HeaderPartyGrey;
            border-radius: 50%;

            &:hover {
                background-color: $HeaderPartyGreyHover;
            }

            svg {
                @include width-height(20px, 20px);
            }
        }

        .party-separtor {
            @include width-height(1px, 25px);
            background-color: $HeaderPartyGrey;
        }
        
    }
}


.party-button {
    all: unset;
    cursor: pointer;
    @include flex-row-alignCenter();
    gap: 3px;
    font-size: 11px;
    background-color: $HeaderPartyGrey;
    padding: 6px;
    border-radius: 3px;
    font-weight: bold;
    text-transform: uppercase;

    svg {
        @include width-height(20px, 20px);
        padding: 2px;
        border: 1.5px solid white;
        border-radius: 50%;
    } 
}

@media screen and (max-width: $BreakPoint_500) {
    .header-party-container {
        display: none;
    }
    .party-button {
        display: none;
    }

}