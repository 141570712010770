@use "../Variables.scss" as *;
@use "../Mixins.scss" as *;

.header {
    background-color: $DarkPalette;
    min-width: 100%;
    height: $headerHeight;
    min-height: $headerHeight;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $HeaderMarge;
    position: relative;

    .header-logo {
        font-family: Silkscreen;
        text-transform: uppercase;
        font-size: 40px;
        position: relative;

        &::after {
            content: "v1";
            position: absolute;
            font-size: 11px;
            bottom: 5px;
            font-family: auto;
        }
    }

    .header-right {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
            cursor: pointer;
        }

        .icons-header {
            margin-right: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }

        .header-profile {
            @include flex-row-alignCenter();
            font-size: 20px;
            margin-right: 30px;

            .header-avatar {
                margin-right: 10px;
                @include avatar-player(50px);
            }
        }
    }

    .header-right-responsive {
        display: none;
        .header-avatar {
            @include avatar-player(40px);
            margin-left: 8px;
        }

        .responsive-menu-drawer {
            width: 140px;
            background-color: $DarkPalette;
            z-index: 99;
            position: absolute;
            top: 45px;
            right: 0;
            border: 0.5px solid $WhitePalette;
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                li {
                    text-align: center;
                    cursor: pointer;
                    padding: 10px;
                    &:not(&:first-child) {
                        margin-top: 7px;
                    }
                    &:hover {
                        background-color: $LightDarkPalette;
                    }
                }
            }
        }
    }

    .notif-dropdown-wrapper {
        position: absolute;
        right: 200px;
        top: 70px;
        border-radius: 5px;
        background-color: $DarkPalette;
        width: 330px;
        max-height: 350px;
        min-height: 50px;
        z-index: 101;
        padding: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        @include overflowScroll("y");
        .no-notif {
            margin: auto;
        }
        .notif-dropdown-item {
            background-color: $LightDarkPalette;
            padding: 12px;
            border-radius: 5px;
            @include flex-row-alignCenter();
            justify-content: space-between;
            position: relative;

            .profile-avatar {
                @include avatar-player(40px);
            }
            .notif-content {
                width: 100%;
                margin: 0 5px 0 10px;
                @include overflowScroll("");
                p {
                    margin: 0;
                    &:first-child {
                        font-size: 16px;
                        margin-bottom: 5px;
                    }
                    &:last-child {
                        font-size: 11px;
                    }
                }
            }
            .notif-buttons {
                display: flex;
                flex-direction: row;
                gap: 7px;
                svg {
                    @include circleButtons(20px);
                }
            }
        }
    }
}

.badge-wrapper {
    display: flex;
    position: relative;
    cursor: pointer;
    .badge {
        position: absolute;
        border-radius: 50%;
        background-color: red;
        text-align: center;
        font-size: 10px;
        font-weight: bold;
    }

    .badge-notif {
        @include width-height(15px, 15px);
        right: -3px;
        top: -4px;
    }

    .badge-message {
        @include width-height(10px, 10px);
        right: -3px;
        top: -3px;
    }
}

@media screen and (max-width: $BreakPoint_800) {
    .header {
        padding: 0 $HeaderResponsive800Marge;
        .header-logo {
            font-size: 30px;
        }
        .header-right {
            display: none;
        }
        .header-right-responsive {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            gap: 3px;
        }

        .notif-dropdown-wrapper {
            right: 20px;
        }
    }
}

@media screen and (max-width: $BreakPoint_500) {
    .header {
        padding: 0 $HeaderResponsive500Marge;
        .header-logo {
            font-size: 25px;
        }
    }
}