@use '../Variables.scss' as *;
@use "../Mixins.scss" as *;

.no-target-message {
    font-size: 30px;
    margin: auto;
    text-align: center;
}

.chat-page-container, .chat-party-sidebar {
    @include page-wrapper();
    background-color: $LightDarkPalette;
    display: flex;
    flex-direction: row;
    flex-flow: row;
    .chat-sidebar {
        @include width-height(270px, 100%);
        min-width: 270px;
        max-width: 270px;
        background-color: $LightDarkPalette;
        border-right: 1px solid $WhitePalette;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .explore-button {
           background-color: $DarkPalette;
           padding: 15px;
           text-align: center;
        }

        .ul-wrapper {
            position: relative;
            list-style-type: none;
            margin-right: 30px;
            @include overflowScroll("y");
            &-elem {
                .collapse-button {
                    @include flex-row-alignCenter;
                    justify-content: space-between;
                    height: 35px;
                    svg {
                        cursor: pointer;
                    }
                    &-name {
                        @include flex-row-alignCenter;
                        gap: 5px;
                    }
                    .plus-icon {
                        @include width-height(20px, 20px);
                    }
                }

                .ul-collapse {
                    list-style-type: none;
                    padding-left: 15px;
                    li:not(li:first-child) {
                        margin-top: 2px;
                    }                    

                    .list-item-container {
                        text-decoration: none;
                        display: flex;
                        a {
                            width: 100%;
                            padding: 8px;
                            border-radius: 5px;
                            color: #c2c2c2;
                            &:hover {
                                background-color: $DarkPalette;
                            }
                        }

                        .new-msg {
                            color: white;
                            font-weight: bold;
                        }
    
                        a[is-target="true"] {
                            background-color: $DarkPalette;
                        }

                        .public-chan-item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .icon-container {
                                display: flex;
                                align-items: center;
                                span {
                                    font-size: 15px;
                                }
                                svg {
                                    &:first-child {
                                        @include width-height(17px, 17px);
                                    }
                                    &:last-child {
                                        cursor: pointer;
                                        @include width-height(20px, 20px);
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .message-container-main {
        @include width-height(85%, 100%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        list-style-type: none;
        .message-header {
            @include width-height(100%, 50px);
            background-color: $LightDarkPalette;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $WhitePalette;

            &-right-side {
                margin-right: 20px;
                @include flex-row-alignCenter();
                svg {
                    @include width-height(23px, 23px);
                    cursor: pointer;
                }
                .chat-action-icones {
                    margin-right: 28px;
                    @include flex-row-alignCenter();
                    gap: 10px;
                    a {
                        @include width-height(23px, 23px);
                        margin: 0
                    }
                }
                
            }

            .burger-icon-responsive {
                display: none;
                cursor: pointer;
            }

            .chan-name {
                margin-left: 20px;
            }

            a {
                margin-right: 10px;
            }
        }

        .header-user-info {
            @include width-height(100%, 50px);
            background-color: $LightDarkPalette;
            border-bottom: 1px solid $WhitePalette;
            @include flex-row-alignCenter;
            position: relative;

            .close-party-chat {
                position: absolute;
                cursor: pointer;
            }

            .p-party-chat {
                margin-left: auto;
                margin-right: auto;
            }

            .burger-icon-responsive {
                display: none;
                cursor: pointer
            }
            .player-container {
                @include flex-row-alignCenter;

                .spectate-icon {
                    @include width-height(14px, 14px);
                    margin-left: 10px;
                    margin-top: 2px;
                    cursor: pointer;
                }

                p {
                    cursor: pointer;
                }
                .player-status {
                    @include player-status;
                }
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 10px 0 20px 0;
            height: 90%;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            &:hover {
                @include overflowScroll("y");
            }

            .message-server {
                margin: 0 auto;
            }

            .loader-wrapper {
                margin: 0 auto;
                .prev-messages-loader {
                    @include width-height(23px, 23px);
                    border: 3px solid #FFF;
                    border-bottom-color: transparent;
                    border-radius: 50%;
                    display: inline-block;
                    box-sizing: border-box;
                    animation: rotation 1s linear infinite;
                }
            }
            
            .message-item-container {
                display: flex;
                flex-direction: row;
                gap: 15px;
                padding: 4px 0 4px 20px;
                position: relative;
                word-break: break-all;
                &:hover {
                    background-color: $DarkPalette;
                }
                img {
                    @include avatar-player(45px);
                }
                .message-content-wrapper {
                    .message-info-wrapper {
                        @include flex-row-alignCenter();
                        gap: 10px;
                        span {
                            &:first-child {
                                color: orange;
                                cursor: pointer;
                            }
                            &:last-child {
                                margin-top: 2.5px;
                                font-size: 10px;
                            }
                        }
                    }
                    .message-text {
                        margin: 0;
                        font-size: 13px;
                        margin-top: 5px;
                        padding-right: 20px;
                    }
                }
                
            }

            .message-item-container-2 {
                display: flex;
                align-items: center;
                padding: 4px 0 4px 80px;
                position: relative;
                margin: 0;
                font-size: 13px;
                word-break: break-all;
                .span-message {
                    padding-right: 20px;
                }
                .date-message {
                    width: 45px;
                    text-align: center;
                    display: none;
                    position: absolute;
                    font-size: 10px;
                    left: 18px;
                }
                &:hover {
                    background-color: $DarkPalette;
                    .date-message {
                        display: block;
                    }
                }
            }
        }
    }
    
    .message-input-container {
        @include flex-row-alignCenter;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        background-color: $LightDarkPalette;
        form {
            border-top: 1px solid $WhitePalette;
            width: 90%;
            @include flex-row-alignCenter();
            margin: auto;
            position: relative;

            p {
                position: absolute;
                margin: 0;
                top: -20px;
                font-size: 13px;
            }
            
            input {
                all: unset;
                width: 95%;
                padding: 18px 20px;
            }

            button {
                all: unset;
                svg {
                    cursor: pointer;
                    background-color: $OrangeProfile;
                    border-radius: 50%;
                    @include width-height(30px, 30px);
                    padding: 5px;
                    background: linear-gradient(112deg, rgba(255,94,0,1) 0%, rgba(255,145,0,1) 50%, rgba(255,154,0,1) 100%);
                }
            }  
        }
    }

    .users-sidebar {
        @include width-height(300px, 100%);
        background-color: $DarkPalette;
        position: relative;
        
        &-wrapper {
            margin: 10px;
            height: 95%;
            @include overflowScroll("y");
            h3 {
                margin: 0;
                color: #a5abb0;
                font-size: 15px;
            }
            ul {
                list-style-type: none;
                padding: 0;
                li {
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 5px;
                    @include avatar-status-container($DarkPalette);

                    p {
                        cursor: pointer;
                    }

                    .spectate-icon {
                        @include width-height(15px, 15px);
                        cursor: pointer;
                    }

                    &:hover {
                        background-color: $LightDarkPalette;
                        .avatar-container {
                            .user-status {
                                background-color: $LightDarkPalette;
                            }
                        }
                    }
                }
            }
        }
    }

    .sidebar-button {
        display: none;
    }

    .channels-list-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px;
        height: fit-content;
        @include overflowScroll("y");

        .channels-card-wrapper {
            background-color: $DarkPalette;
            @include width-height(300px, 240px);
            position: relative;

            .channel-banner {
                height: 110px;
                background: url('../../Assets/Backgrounds/background-42.jpg') no-repeat center;
                background-size: cover;
            }

            .card-content {
                padding: 10px;

                .channel-name {
                    margin: 0;
                    font-size: 19px;
                    font-weight: bold;
                    span {
                        font-size: 10px;
                    }
                }

                .channel-description {
                    font-size: 12px;
                    font-family: OpenSans;
                }
    
                .item-bottom {
                    width: 90%;
                    position: absolute;
                    @include flex-row-alignCenter();
                    justify-content: space-between;
                    bottom: 0;
                    p {
                        font-size: 14px;
                    }
                    &-right {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        svg {
                            cursor: pointer;
                            background-color: $OrangeProfile;
                            border-radius: 50%;
                            @include width-height(25px, 25px);
                            padding: 3px;
                            background: linear-gradient(112deg, rgba(255,94,0,1) 0%, rgba(255,145,0,1) 50%, rgba(255,154,0,1) 100%);
                        }
                        input {
                            all: unset;
                            border: 1px solid white;
                            border-radius: 5px;
                            padding: 3px;
                            width: 100px;
                            font-size: 13px;
                        }
                    }
                }
            } 
        }
    }
}

@media screen and (max-width: $BreakPoint_800) {
    .chat-page-container {
        .chat-sidebar {
            display: none;
            position: absolute;
            z-index: 99;
            height: 100%;
            min-width: 250px;
            max-width: 250px;
            font-size: 15px;
            .ul-wrapper {            
                &-elem {
                    .collapse-button {
                        svg {
                            @include width-height(20px, 20px);
                        }
                        &-name {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
        .chat-sidebar-responsive {
            display: flex;
        }

        .message-container-main { 
            width: 100%;
            .message-header {
                padding: 0 $HeaderResponsive800Marge;
                &-right-side {
                    margin-right: 0px;
                    .chat-action-icones {
                        margin-right: 0px;
                    }
                }
                .burger-icon-responsive {
                    display: block;
                }
            }

            .header-user-info {
                padding: 0 $HeaderResponsive800Marge;
                .burger-icon-responsive {
                    display: block;
                    position: absolute;
                }
                .player-container {
                    margin: 0 auto
                }
            }

            .ul-container {
                ul {
                    .message-item {
                        img {
                            @include avatar-player(35px);
                        }
                        &-info {
                            .message-text {
                                font-size: 14px;
                                padding: 8px 13px;
                            }
                        }
                    }
                }
            }
        } 
        

        .sidebar-button {
            display: block;
            position: absolute;
            left: -24px;
            top: 25px;
            z-index: 99;
            border-radius: 50%;
            @include width-height(45px, 45px);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: $DarkPalette;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: $BreakPoint_500) {
    .chat-page-container {
        .message-container-main { 
            .message-header {
                padding: 0 $HeaderResponsive500Marge;
            }

            .header-user-info {
                padding: 0 $HeaderResponsive500Marge;
            }            

            .ul-container {
                ul {
                padding-left: $HeaderResponsive500Marge;
                }
            }
        }

        .channels-list-wrapper {
            .channels-card-wrapper {
                @include width-height(220px, 240px);
                .card-content {
                    .channel-name {
                        font-size: 13px;
                    }

                    .channel-description {
                        font-size: 10px;
                    }

                    .item-bottom {
                        p {
                            font-size: 10px;
                        }

                        &-right {
                            svg {
                                @include width-height(20px, 20px);
                            }
                            input {
                                padding: 3px;
                                width: 80px;
                                font-size: 10px;
                            }
                        }
                    }
                }                
            }
        }
    }
}

@media screen and (max-width: 470px) {
    .chat-page-container {
        .channels-list-wrapper {
            .channels-card-wrapper {
                @include width-height(250px, 270px);
            }
        }
    }
}