@use "./Variables.scss" as *;
@use "./Mixins.scss" as *;

$GreyLobby: rgb(207, 203, 203);
$TeamBlueColor: rgb(0, 98, 255);
$TeamRedColor: rgb(247, 30, 6);

.lobby-container {
    background-color: $LightDarkPalette;
    @include page-wrapper();

    .lobby-wrapper {
        height: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        .lobby-player-list {
            @include flex-row-alignCenter();
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
            list-style-type: none;
            margin: 0;
            padding: 0;
            .empty-item {
                justify-content: center;

                svg {
                    @include width-height(30px, 30px);
                    cursor: pointer;
                }
            }
            
            .team-blue {
                background-color: rgba(0, 98, 255, 0.57);
                box-shadow: rgba(13, 110, 255, 0.25) 0px 12px 28px 0px, rgba(13, 110, 255, 0.25) 0px 2px 4px 0px, rgba(13, 110, 255, 0.25) 0px 0px 0px 1px inset;
                border: 1.5px solid $TeamBlueColor
            }
            .team-red {
                background-color: rgba(249, 25, 0, 0.72);
                box-shadow: rgba(255, 13, 13, 0.25) 0px 12px 28px 0px, rgba(255, 13, 13, 0.25) 0px 2px 4px 0px, rgba(255, 13, 13, 0.25) 0px 0px 0px 1px inset;
                border: 1.5px solid $TeamRedColor
            }

            li {
                @include width-height(200px, 200px);
                padding: 30px 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: #131d30;
                border-radius: 15px;
                position: relative;
                .teams-wrapper {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    @include flex-row-alignCenter();
                    gap: 5px;

                    .team1 {
                        background-color: $TeamBlueColor;
                    }

                    .team2 {
                        background-color: $TeamRedColor;
                    }

                    .team-active {
                        border: 1.5px solid white
                    }

                    .circle-item {
                        @include width-height(17px, 17px);
                        border-radius: 50%;
                        cursor: pointer
                    }
                }
                
                .avatar-shadow {
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                }
                .player-avatar {
                    @include avatar-player(80px);
                }
    
                p {
                    margin: 10px 0 0 0;
                    font-size: 15px;
                    font-weight: bold;
                }
                
                .team-select {
                    cursor: pointer;
                    padding: 5px;
                    background-color: #1B263B;
                    border-radius: 5px;
                    border: none;
                    color: white;
                    margin-top: 5px;
                    font-size: 11px;
                }
                
                .player-pos {
                    margin-top: 10px;
                    font-size: 10px;
                }
    
                span {
                    @include flex-row-alignCenter();
                    gap: 5px;
                    font-size: 12px;
                    svg {
                        @include width-height(12px, 12px);
                        border: 1px solid ;
                        border-radius: 50%;
                    }
                }
            }
        }

        .lobby-settings {
            @include flex-row-alignCenter();
            gap: 20px;
            .setting-wrapper {
                background-color: $DarkPalette;
                border-radius: 10px;
            }

            .game-settings {
                @include width-height(350px, 350px);
                padding: 10px ;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                position: relative;
    
                p {
                    text-align: center;
                    margin: 0;
                }

                .lock-icon {
                    margin: auto;
                    @include width-height(60px, 60px);
                }
    
                form {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 5px;

                    fieldset {
                        all: inherit;
                    }

                    label {
                        @include flex-row-alignCenter();
                        justify-content: space-between;
                        font-size: 13px;
                        position: relative;
                        margin-right: 25px;

                        input[type="range"] {
                            accent-color: $LightBlueButton;
                        }

                        .input-value {
                            position: absolute;
                            right: -25px;
                            color: $LightBlueButton;
                            font-size: 15px;
                        }
                    }

                    .setting-submit {
                        @include blue-button(14px, 6px);
                        margin: 15px auto 0 auto;
                    }
                }
            }

            .board-game-wrapper {
                $PaddleBackSize: 150px;
                $PaddleFrontSize: 150px;
                $PlayerBackAdvance: 10px;
                $PlayerFrontAdvance: 40px;
                @include width-height(500px, 350px);

                .game-board {
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    overflow: hidden;
                    position: relative;

                    .left-wrapper {
                        .paddle-front {
                            left: var(--PlayerFrontAdvance);
                        }

                        .paddle-back {
                            left: var(--PlayerBackAdvance);
                        }
                    }

                    .right-wrapper {
                        .paddle-front {
                            right: var(--PlayerFrontAdvance);
                        }

                        .paddle-back {
                            right: var(--PlayerBackAdvance);
                        }
                    }

                    .paddle-wrapper {
                        @include flex-row-alignCenter();
                        position: relative;
                        .paddle-left {
                            background-color: $TeamBlueColor;
                        }
    
                        .paddle-right {
                            background-color: $TeamRedColor;
                        }

                        .paddle-front {
                            height: var(--PaddleFrontSize);
                        }

                        .paddle-back {
                            height: var(--PaddleBackSize);
                        }
    
                        .paddle {
                            width: 2px;
                            position: absolute;
                        }
                    }

                    .ball {
                        @include width-height(20px, 20px);
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        border-radius: 50px;
                        background-color: aqua;
                    }
                }
               
            }
        }

        .lobby-buttons-wrapper {
            @include flex-row-alignCenter();
            gap: 10px;
            justify-content: center;
            position: relative;

            button {
                all: unset;
                text-align: center;
                border-radius: 10px;
                background-color: $DarkPalette;
                width: 250px;
                height: 40px;
                position: relative;
            }

            .lock-button {
                cursor: default !important;
                color: grey;
                position: relative;
                background-color: rgb(0 0 0 / 24%);
                    
                .lock-icon {
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    color: rgba(255, 255, 255, 0.772);
                }
            }

            .start-button {
                cursor: pointer;
                position: relative;
                text-transform: uppercase;
                font-size: 14px;
            }

            .start-button[lobby-error]{
                cursor: default;
                background-color: rgb(0 0 0 / 24%);
                color: grey;
                .lock-icon {
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    color: rgba(255, 255, 255, 0.772);
                }
            }

            .start-button[lobby-error]:after {
                content: attr(lobby-error);
                text-transform: initial;
                color: red;
                font-size: 10px;
                position: absolute;
                top: -20px;
                left: 0;
                padding: 5px;
                width: 100%;
            }

            .queue-button {
                position: relative;

                svg {
                    position: absolute;
                    left: 10px;
                    display: flex;
                    align-items: center;
                    @include width-height(20px, 20px);
                    cursor: pointer;

                    border: 1px solid red;
                    color: red;
                    padding: 1.5px;
                    border-radius: 50%;
                }
            }

            .game-modes-dropdown {
                position: absolute;
                width: 32.5%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                background-color: $DarkPalette;
                top: -94px;
                right: 0;
                text-align: center;

                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    .gameMode-lock {
                        cursor: auto;
                        color: grey;
                        position: relative;
                        background-color: rgb(0 0 0 / 24%);
                        
                        .lock-icon {
                            position: absolute;
                            right: 0;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            color: rgba(255, 255, 255, 0.772);
                        }
                    }
                    li {
                        &:first-child {
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                        }
                        padding: 6px;
                        cursor: pointer;
                        &:hover:not(.gameMode-lock) {
                            background-color: #102031;
                        }
                    }
                }
            }

            .bos {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            .game-modes-button {
                @include flex-row-alignCenter();
                justify-content: center;
                .chevron-icon {
                    position: absolute;
                    right: 10px;
                }
            }
        }   
    }    
}

@media screen and (max-width: $BreakPoint_800) {
    .lobby-container {
        .lobby-wrapper {
            .lobby-player-list {
                li {
                    @include width-height(150px, 150px);
                    padding: 25px 10px;

                    .teams-wrapper {
                        .circle-item {
                            @include width-height(14px, 14px);
                        }
                    }

                    .player-avatar {
                        @include avatar-player(45px);
                    }

                    p {
                        font-size: 12px;
                    }

                    .team-select {
                        padding: 3px;
                        font-size: 8px;
                    }
                }
            }
            
            .lobby-settings {
                flex-direction: column;
            }

            .lobby-buttons-wrapper {
                button {
                    all: unset;
                    text-align: center;
                    border-radius: 10px;
                    background-color: $DarkPalette;
                    padding: 13px 60px;
                    font-size: 12px;
                    position: relative;
                }

                .game-modes-button {
                    .chevron-icon {
                        @include width-height(15px, 15px);
                    }
                }

                .game-modes-dropdown {
                    width: 29.8%;
                    font-size: 12px;
                    top: -77px;
                }
            }
        }
    }
}

@media screen and (max-width: $BreakPoint_500) {
    .lobby-container {
        .lobby-wrapper {
            .lobby-player-list {
                li {
                    @include width-height(130px, 130px);
                    padding: 20px 10px;
                    
                    .player-avatar {
                        @include avatar-player(35px);
                    }

                    p {
                        font-size: 12px;
                    }
                }
            }

            .lobby-settings {
                flex-direction: column;
                .board-game-wrapper {
                    display: none;
                }
            }

            .lobby-buttons-wrapper {
                button {
                    all: unset;
                    text-align: center;
                    border-radius: 10px;
                    background-color: $DarkPalette;
                    padding: 12px 40px;
                    font-size: 10px;
                    position: relative;
                }

                .game-modes-button {
                    .chevron-icon {
                        @include width-height(15px, 15px);
                    }
                }

                .game-modes-dropdown {
                    width: 29%;
                    font-size: 10px;
                    top: -70px;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .lobby-container {
        .lobby-wrapper {
            .lobby-player-list {
                li {
                    @include width-height(130px, 130px);
                    padding: 20px 10px;

                    .player-avatar {
                        @include avatar-player(35px);
                    }

                    p {
                        font-size: 12px;
                    }
                }
            }

            .lobby-buttons-wrapper {
                button {
                    all: unset;
                    text-align: center;
                    border-radius: 10px;
                    background-color: $DarkPalette;
                    padding: 10px 30px;
                    font-size: 8px;
                    position: relative;
                }

                .game-modes-button {
                    .chevron-icon {
                        @include width-height(15px, 15px);
                    }
                }

                .game-modes-dropdown {
                    width: 29%;
                    font-size: 10px;
                    top: -70px;
                }
            }
        }
    }
}