@use "./Variables.scss" as *;

@mixin page-wrapper {
    min-height: 100%;
    overflow: auto;
}

@mixin blue-button($fontSize, $padding) {
    all: unset;
    cursor: pointer;
    font-size: $fontSize;
    border: 1px solid $LightBlueButton;
    color: $LightBlueButton;
    padding: $padding;
    border-radius: 3px;
    font-weight: bold;
    margin-top: 5px;
    text-align: center;

    &:hover {
        background-color: $LightBlueButton;
        color: #0D1B2A;
    }
}

@mixin tooltip {
    cursor: auto;
    position: absolute;
    height: 0;
    background-color: $LightDarkPalette;
    border-radius: 5px;
    font-size: 10px;
    z-index: 9999;
    opacity: 0;
    transition: 0s;
    text-align: center;
}

@mixin player-status {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    margin: 0 10px 0 20px;
    
    &-online {
        background-color: $OnlineColor;
    }

    &-offline {
        background-color: $OfflineColor;
    }
}

@mixin avatar-player($size) {
    height: $size;
    width: $size;
    border-radius: 50%;
}

@mixin sidebar-button() {
    position: absolute;
    top: 25px;
    border-radius: 50%;
    @include width-height(45px, 45px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 7px;
    background-color: $DarkPalette;
    cursor: pointer;
}

@mixin avatar-status-container($background) {
    .avatar-container {
        position: relative;
        .user-avatar {
            @include avatar-player(40px);
        }
        .user-status {
            @include width-height(20px, 20px);
            border-radius: 50%;
            background-color: $background;
            position: absolute;
            right: -3px;
            top: 25px;
            padding: 2.5px 0 0 2.5px;
            div {
                @include width-height(14px, 14px);
                border-radius: 50%;
            }
            .online {
                background-color: $OnlineColor;
            }
            .offline {
                background-color: $OfflineColor;
            }
        }
    }
}

@mixin modal-container($height, $width, $bgColor) {
    z-index: 100;
    height: $height;
    width: $width;
    position: absolute;
    left:0;
    right:0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 10px;
    background-color: $bgColor;
    @include overflowScroll("y");
    .modal-exit {
        position: absolute;
        right: 5px;
        top: 5px;
        width: 20px;
        height: 20px;
        color: $WhitePalette;
        cursor: pointer;
    }

    @media screen and (max-height: 700px) {
        height: 97%;
    }

    @media screen and (max-width: $BreakPoint_800) {
        width: 90%;
    }
}

@mixin flex-row-alignCenter {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin width-height($width, $height) {
    width: $width;
    height: $height;
}

@mixin overflowScroll($typeOverflow) {
    @if $typeOverflow == "x" {
        overflow-x: scroll;
    } @else if $typeOverflow == "y" {
        overflow-y: scroll;
    } @else {
        overflow: scroll;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin circleButtons($size) {
    cursor: pointer;
    border-radius: 50%;
    @include width-height($size, $size);
    padding: 2px;
    &:first-child {
        color: green;
        border: 1px solid green;
    }
    &:last-child {
        color: red;
        border: 1px solid red;
    }
}