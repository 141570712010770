@use "./Variables.scss" as *;
@use "./Mixins.scss" as *;

.resume-game-container {
    position: absolute;
    top: $headerHeight;
    right: 0;
    background-color: $DarkPalette;
    z-index: 9999;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom-left-radius: 10px;

    p {
        text-align: center;
    } 

    button {
        margin: 0 auto;
        @include blue-button(14px, 5px)
    }
}