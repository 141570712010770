@use "./Header/Header.scss";
@use "./Header/Party-Element.scss";
@use "./Header/Queue-Timer.scss";
@use "./Home.scss";
@use "./Sign.scss";
@use "./Profile.scss";
@use "./Profile-Settings.scss";
@use "./Leaderboard.scss";
@use "./Friends-Modal.scss";
@use "./Chat/Chat.scss";
@use "./Chat/Chat-Modal.scss";
@use "./Search-Player.scss";
@use "./Chat/Channel-Settings.scss";
@use "./Loading-Spin.scss";
@use "./Notif-Game-Invite.scss";
@use "./Notif-Alert.scss";
@use "./Lobby.scss";
@use "./Chat-Party.scss";
@use "./Match-Found.scss";
@use "./Alert-Validation.scss";
@use "./Resume-Game.scss";
@use "./Dropdown.scss";
@use "./Error-404-Page.scss";
@use "./Variables.scss" as *;
@use "./Mixins.scss" as *;

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

html, body, #root{
	margin: 0;
	padding: 0;
	border: 0;
	max-height: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

body {
	font-family: Montserrat;
	text-decoration: none;
	color: $WhitePalette;
	flex-grow: 1;
}

#root {
	flex-grow: 1;
}

@keyframes fadeIn {
	99% {
	  visibility: hidden;
	}
	100% {
	  visibility: visible;
	}
}

*[data-tooltips] {
	position: relative;
	&::after {
		content: attr(data-tooltips);
		@include tooltip();
		bottom: -30px;
	}

	&:hover {
		&::after {
			height: auto;
			opacity: 1;
  			transition-delay: 0.8s;
			padding: 5px;
		}
	}
}

.app-container {
	inset: 0;
	display: flex;
	position: absolute;
}

.page-container {
	top: $headerHeight;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	position: absolute;
	flex-flow: column;
	min-height: 0;
	background-size: cover;
}

input:-webkit-autofill {
	transition: background-color 5000s ease-in-out 0s !important; 
	-webkit-text-fill-color: rgb(255, 255, 255) !important;
}

a, button{
	color: $WhitePalette;
	text-decoration: none;
}

button {
	cursor: pointer;
}

.blur-background {
	filter: blur(5px);
}

#game_anchor {
	@include page-wrapper();
	background-color: $BlueDarkPalette;
}

.err-no-datas {
	color: $WhitePalette;
	text-align: center;
	font-size: 25px;
}

.txt-form-error {
	color: red;
	margin: 0;
	font-size: 11px;
	letter-spacing: 1px;
}

.tooltip-icon-wrapper {
	display: flex;
}