@use '../Variables.scss' as *;
@use "../Mixins.scss" as *;

.channel-setting-container {
    @include page-wrapper();
    display: flex;
    flex-direction: row;
    color: $WhitePalette;

    .sidebar-button {
        display: none;
    }
    
    .sidebar-setting {
        padding-top: 30px;
        background-color: $DarkPalette;
        width: 350px;

        .sidebar-button {
            display: none;
        }
        
        .sidebar-wrapper {
            margin-left: $HeaderMarge;
            display: flex;
            flex-direction: column;

            p {
                margin: 0 0 5px 10px;
                color: rgb(190, 181, 181);
            }

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
    
                li {
                    &:not(&:first-child) {
                        margin-top: 2px;
                    }
                    padding: 10px;
                    border-radius: 5px;
                    margin-right: 5px;
                    cursor: pointer;

                    &:hover {
                        background-color: $LightDarkPalette;
                    }
                }
            }
    
            .separate-line {
                height: 2px;
                background-color: $LightDarkPalette;
                margin: 5px 0;
            }
    
            button {
                all: unset;
                padding: 10px;
                border-radius: 5px;
                cursor: pointer;
                color: red;
                margin-right: 5px;

                &:hover {
                    background-color: $LightDarkPalette;
                }
            }
        } 
    }
   
    .content-setting-container {
        background-color: $LightDarkPalette;
        width: 100%;
        padding-top: 30px;
        display: flex;
        flex-direction: row;
        gap: 15px;

        .content-wrapper {
            margin-left: 50px;
            height: 100%;
            width: 90%;

            form {
                &:first-child {
                    margin-bottom: 15px;
                }
            }

            .radio-form {
                display: flex;
                flex-direction: column;
                gap: 5px;

                button {
                    @include blue-button(14px, 7px);
                }

                .saveInput {
                    margin-top: 10px;
                    width: 40px;
                }

                .label-input-wrapper {
                    @include flex-row-alignCenter();
                    gap: 5px;

                    input {
                        all: unset;
                        width: 200px;
                        background-color: $DarkPalette;
                        border: 1px solid white;
                        border-radius: 3px;
                        padding: 7px;
                        color: white;
                        font-size: 13px;
                        cursor: text;
                    }
                }

                label {
                    input[type="radio"] {
                        accent-color: $LightBlueButton;
                    }
                }
                
                
                .labelTextInput {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    font-size: 15px;
                    margin: 5px 0 10px 5px;
                    input {
                        all: unset;
                        width: 300px;
                        cursor: text;
                        padding: 8px;
                        border: 1px solid $WhitePalette;
                        margin-top: 2px;
                        &::placeholder {
                            font-size: 12px;
                        }
                    }
                }
            }
            
            .user-list-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                height: 100%;
                @include overflowScroll("y");
                h3 {
                    margin: 0;
                    padding: 0;
                    color: rgb(190, 181, 181);
                }
                ul {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    margin-bottom: 15px;
                    .setting-user-item {
                        width: 300px;
                        @include flex-row-alignCenter;
                        justify-content: space-between;
                        .profil-container {
                            @include flex-row-alignCenter;
                            gap: 10px;
                            .profile-avatar {
                                @include avatar-player(45px);
                            }
                        }
                        .user-dropdown-container {
                            position: relative;
                            svg {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            table {
                border-collapse: collapse;
                text-align: left;
                width: 100%;
                background-color: $DarkPalette;

                thead {
                    th {
                        padding: 25px;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }

                tbody {
                    tr {
                        &:hover td {
                            background-color: #202d45;
                        }
        
                        td {
                            padding: 15px 25px;
                            background: $LightDarkPalette;
                            font-size: 16px;
                            border-bottom: 5px solid $DarkPalette;
        
                            .user-info {
                                @include flex-row-alignCenter();
                                gap: 10px;
                                .user-avatar {
                                    @include avatar-player(30px);
                                }
                            }

                            button {
                                all: unset;
                                cursor: pointer;
                                padding: 5px;
                                font-size: 14px;
                                font-weight: bold;
                                color: $LightBlueButton;
                                border: 1px solid $LightBlueButton;
                                border-radius: 5px;

                                &:hover {
                                    color: $LightDarkPalette;
                                    background-color: $LightBlueButton;
                                }
                            }
                        }
                    }
                }
            }
        }
        .leave-icon-wrapper {
            width: 10%;
            display: flex;
            .leave-icon {
                margin: 0 auto;
                @include width-height(40px, 40px);
                padding: 5px;
                color: $WhitePalette;
                cursor: pointer;
                border: 2px solid $WhitePalette;
                border-radius: 50%;
            }
        }
    }
}

@media screen and (max-width: $BreakPoint_800) {
    .channel-setting-container {
        .sidebar-button {
        @include sidebar-button();
    }
        .sidebar-setting {
            width: 250px;
            position: absolute;
            height: 100%;

            .sidebar-button {
                @include sidebar-button();
            }
            .sidebar-wrapper {
                margin-left: $HeaderResponsive800Marge;
            }
        }
    }
}

@media screen and (max-width: $BreakPoint_500) {
    .channel-setting-container {
        .sidebar-setting-container {
            .sidebar-setting {
                .sidebar-wrapper {
                    margin-left: $HeaderResponsive500Marge;
                }
            }
        }
    }
}