@use "./Variables.scss" as *;
@use "./Mixins.scss" as *;

.dropdown-container {
	position: absolute;
	width: 200px;
	border-radius: 5px;
	background-color: $DarkPalette;
	z-index: 9999;

	.dropdown-wrapper {
		margin: 10px;
		display: flex;
		flex-direction: column;
		gap: 2px;

		.red-item {
			color: red;
		}
		a {
			color: #a5abb0;
		}

		.dropdown-button {
			position: relative;
			display: flex;
			&:hover {
				.right-menu-dropdown {
					display: flex;
				}
			}
			span {
				width: 100%;
			}
			.right-menu-dropdown {
				position: absolute;
				right: -150px;
				bottom: -20px;
				background-color: $DarkPalette;
				padding: 5px;
				width: 150px;
				display: none;
				flex-direction: column;
				gap: 3px;
				&:hover {
					display: flex;
				}
				&-separator {
					text-align: center;
					font-size: 10px;
				}
				p {
					text-align: center;
				}
				.input-dropdown-wrapper {
					width: 100%;
					display: flex;
					flex-direction: column;
					padding: 5px;
					margin: 0 auto;

					.timeout-error {
						font-size: 10px;
						color: red;
					}

					.input-container {
						@include flex-row-alignCenter();
						justify-content: space-between;
						width: 100%;

						input {
							all: unset;
							width: 80%;
						}
						
						button {
							all: unset;
							display: flex;
							svg {
								@include width-height(15px, 15px);
							}
						}
					}
					&:hover {
						background-color: $LightDarkPalette;
					}
				}
			}
		}

		p, .dropdown-button {
			margin: 0;
			font-size: 13px;
			letter-spacing: 1.5px;
			padding: 5px;
			border-radius: 5px;
			cursor: pointer;
			color: #a5abb0;

			&:hover {
			background-color: $LightDarkPalette;
			}
		}
	}
}