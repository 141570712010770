@use "./Variables.scss" as *;
@use "./Mixins.scss" as *;

.modal-wrapper-container {
    position: absolute;
    @include width-height(100vw, 100vh);
    background-color: $DarkPalette;
    .add-friend-modal-wrapper {
        @include modal-container(500px, 500px, $LightDarkPalette); // Params => Height, Width, Background-color
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

        .modal-header {
            width: 100%;
            display: flex;
            flex-direction: row;
            border-bottom: 0.5px solid $WhitePalette;
            margin-bottom: 20px;

            &-selected {
                color: $LightBlueButton;
            }
            div {
                @include width-height(50%, 100%);
                p {
                    text-align: center;
                    cursor: pointer;
                }

                &:first-child {
                    border-right: 0.5px solid $WhitePalette;
                }
            }
           
        }

        .modal-friend-list {
            height: 400px;
            p {
                margin: auto;
                font-size: 22px;
            }
        }

        .search-player-container {
            .modal-player-list {
                height: 350px;
            }
        }
    }
}

.modal-player-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
    @include overflowScroll("y");
    &-item {
        width: 90%;
        @include flex-row-alignCenter();
        justify-content: space-between;
        margin: 0 auto;
        .item-player-info {
            display: flex;
            align-items: center;
            gap: 10px;
            @include avatar-status-container($LightDarkPalette);
        }

        button {
            @include blue-button(11px, 7px);
        }

        .pending-button {
            font-size: 14px;
            color: $LightBlueButton;
            padding: 7px;
            margin-top: 5px;
        }

        .friendship-action-wrapper {
            @include flex-row-alignCenter();
            gap: 10px;
            svg {
                @include circleButtons(25px);
            }
        }

        .icons-player-item {
            @include flex-row-alignCenter;
            gap: 10px;
            cursor: pointer;
        }

        input {
            @include width-height(17px, 17px);
        }
    }
}

@media screen and (max-width: $BreakPoint_800) {
    .modal-wrapper-container {
        .add-friend-modal-wrapper {
            @include modal-container(500px, 90%, $LightDarkPalette);
        }
    }
}