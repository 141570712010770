@use "./Variables.scss" as *;
@use "./Mixins.scss" as *;

$FontSizeText: 20px;
$FontSizeTextResponsive: 15px;

.profile-container {
    @include page-wrapper();
    background-color: $LightBluePalette;

    .profile-header {
        background: url('../Assets/Backgrounds/background-42.jpg') no-repeat center fixed;
        background-size: cover;
        @include width-height(100%, 250px);
        position: relative;

        .stats-infos {
            height: 90%;
            @include flex-row-alignCenter();
            flex-wrap: wrap;
            justify-content: center;
            gap: 4em;
            position: relative;

            .mode-select {
                position: absolute;
                left: $HeaderMarge;
                top: 10px;
                padding: 5px;
                background: transparent;
                border: none;
                cursor: pointer;
                font-size: 15px;
                border: 1px solid $LightDarkPalette;
                border-radius: 5px;

                &:focus-visible {
                    outline: none;
                }
            }
        
            .stat-elem {
                margin: auto;
                display: flex;
                flex-direction: column;
                position: relative;
                align-items: center;
                padding-bottom: 20px;

                img {
                    @include width-height(100px, 100px);
                    position: absolute;
                    bottom: -60px;
                }

                p {
                    margin: 0;
                    text-align: center;
                    font-weight: bold;
        
                    &:first-child {
                        font-size: 30px;
                        color: $OrangeProfile;
                        margin-bottom: 5px;
                        text-transform: uppercase;
                    }
        
                    &:last-child {
                        font-size: 25px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        bottom: -10px;
                    }
                }
            }
        }

        .card-info {
            z-index: 0;
            position: absolute;
            bottom: -56px;
            @include flex-row-alignCenter();
            margin-left: $HeaderMarge;

            .profile-avatar {
                @include avatar-player(150px);
            }

            .player-status {
                @include player-status;
            }

            p {
                font-size: $FontSizeText;
            }

            svg {
                @include width-height(22px, 22px);
                cursor: pointer;
            }

            .spectate-icon {
                @include width-height(20px, 20px);
            }

            .settings-icon {
                @include width-height(20px, 20px);
                margin-left: 10px;
                margin-top: 5px;
            }

            .icons-wrapper {
                @include flex-row-alignCenter();
                gap: 10px;
                margin-left: 15px;
                .friend-request-pending {
                    svg {
                        margin: 0 3px 0 0;
                    }
                    @include flex-row-alignCenter();
                    font-size: 14px;
                    background-color: $LightDarkPalette;
                    color: $OrangeProfile;
                    padding: 5px;
                    border-radius: 5px;
                }
    
                .buttons-friend-request {
                    @include flex-row-alignCenter();
                    gap: 10px;
                    button {
                        all: unset;
                        cursor: pointer;
                        width: 80px;
                        text-align: center;
                        font-size: 10px;
                        padding: 5px;
                        font-weight: bold;
                        border-radius: 5px;
    
                        &:first-child {
                            background-color: rgb(11, 106, 11);
                        }
    
                        &:last-child {
                            background-color: rgb(174, 14, 14);
                        }
                    }
                }
    
                .friend-icone {
                    &-add {
                        color: white;
                    }
    
                    &-delete {
                        color: rgb(194, 29, 29);;
                    }
                }
    
                .send-message-icon {
                    svg {
                        color: white;
                        height: 22px;
                        width: 22px;
                    }
                }

                .block-button {
                    @include width-height(20px, 20px);
                }
            }
        }
    }

    .profile-main {
        z-index: 1;
        position: relative;
        margin-left: $HeaderMarge + 160px;

        &-menu {
            display: flex;
            flex-direction: row;
            gap: 5em;
            justify-content: center;
            width: 100%;

            p {
                font-size: $FontSizeText;
                font-weight: bold;
                text-transform: uppercase;
                cursor: pointer;
                padding: 2px;
                height: 30px;
                margin: 20px 0 0 0;
            }
        
            p[is-target="true"] {
                border-bottom: 2px solid white;
            }
        }

        .profile-block-wrapper {
            margin-right: $HeaderMarge;
            margin-bottom: 20px;
            margin-top: 15px;
        }

        .history-list {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .filter-select {
                width: 100px;
                padding: 5px;
                background: transparent;
                border: none;
                cursor: pointer;
                font-size: 15px;
                border: 1px solid $LightDarkPalette;
                border-radius: 5px;

                &:focus-visible {
                    outline: none;
                }
            }
        
            .history-item {
                @include flex-row-alignCenter();
                position: relative;
                justify-content: center;
                gap: 10px;
                padding: 5px;
                background-color: $LightDarkPalette;
                border-radius: 10px;

                .wrapper-2v2 {
                    display: flex;
                    flex-direction: column;
                    gap: 7px
                }

                .player-container {
                    @include flex-row-alignCenter();
                    gap: 10px;
                    width: 100px;
                    .avatar-player {  
                        @include avatar-player(40px);
                    }
                }

                .side-reverse {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                }
        
                .history-score {
                    font-size: 35px;
                    margin: 0 15px;
                    display: flex;
                    align-items: center;

                    .higher-score {
                        color: $OrangeProfile;
                    }

                    span:not(span:nth-child(2)) {
                        font-weight: bolder;
                        width: 40px;
                        text-align: center;
                    }
        
                    span:nth-child(2) {
                        font-size: 18px;
                        margin: 5px 10px 0 10px;
                        font-weight: 500;
                    }
                }
            }
        }

        // .achievement-list {
        //     display: flex;
        //     flex-direction: row;
        //     flex-wrap: wrap;
        //     gap: 1em;

        //     .achievement-item {
        //         background-color: $LightDarkPalette;
        //         @include flex-row-alignCenter();
        //         @include width-height(300px, 100px);

        //         border-radius: 8px;

        //         svg {
        //             @include width-height(40%, 100%);
        //             color: rgb(211, 210, 210);
        //         }

        //         &-content {
        //             @include width-height(60%, 100%);
        //             margin-right: 5px;

        //             p {
        //                 margin: 0;
        //             }
                    
        //             p:first-child {
        //                 margin-top: 1em;
        //                 font-weight: bold;
        //                 font-size: $FontSizeText;
        //                 color: $OrangeProfile;
        //             }

        //             p:last-child {
        //                 margin-top: 0.5em;
        //                 font-size: 15px;
        //             }
        //         }
        //     }
        // }

        .friends-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1em;

            .friend-item {
                background-color: $LightDarkPalette;
                @include flex-row-alignCenter();
                justify-content: space-between;
                @include width-height(240px, 70px);
                border-radius: 8px;

                .friend-content {
                    @include flex-row-alignCenter();
                    gap: 5px;
                    margin-left: 10px;

                    .friend-avatar {   
                        @include avatar-player(40px);
                    }

                    p {
                        cursor: pointer;
                    }
                }

                svg {
                    cursor: pointer;
                    color: $OrangeProfile;
                }

                &-menu {
                    position: relative;
                }   
            }
        }
    }
}


@media screen and (max-width: $BreakPoint_800) {
    .profile-container {
        .profile-header {
            .stats-infos {
                height: 70%;
                .mode-select {
                    left: $HeaderResponsive800Marge;
                }
                .stat-elem {
                    p {
                        &:first-child {
                            font-size: 25px;
                        }
                        &:last-child {
                            font-size: 20px;
                        }
                    }
                }
                
            }
            .card-info {
                margin-left: $HeaderResponsive800Marge;
                bottom: -33px;
                .profile-avatar {
                    @include avatar-player(100px);
                }

                p {
                    font-size: $FontSizeTextResponsive;
                }
            }
        }

        .profile-main {
            margin-left: 0;

            &-menu {
                gap: 2em;
                p {
                    font-size: $FontSizeTextResponsive;
                }
            }

            .profile-block-wrapper {
                margin: 0 $HeaderResponsive800Marge;
            }

            .history-list {
                .history-item {
                    gap: 0;
                    .player-container {
                        font-size: 13px;   
                        // @include overflowScroll("x");
                        .avatar-player {  
                            @include avatar-player(30px);
                        }
                    }
                    .history-score {
                        font-size: 25px;
                        margin: 0 0px;

                        span:nth-child(2) {
                            font-size: 12px;
                            margin: 5px 0 0 0;
                        }
                   }
                }
            }

            .achievement-list {
                margin-left: $HeaderResponsive800Marge;
                justify-content: center;
                .achievement-item {
                    width: 200px;
                    height: 80px;
                    @include width-height(200px, 80px);
                    svg {
                        min-width: 30%;
                        max-width: 30%;
                        height: 100%;
                        color: rgb(211, 210, 210);
                    }

                    &-content {
                        margin-right: 0;
                        margin-left: 3px;
                    }

                    p:first-child {
                        font-size: $FontSizeTextResponsive;
                    }

                    p:last-child {
                        font-size: 11px;
                    }
                }
            }

            .friends-list {
                justify-content: center;

                .friend-item {
                    @include width-height(200px, 70px);
                }
            }
        }
    }
}

@media screen and (max-width: $BreakPoint_500) {
    .profile-container {
        .profile-header {
            .stats-infos {
                padding: 27px;
                gap: 3.5em;
                .mode-select {
                    left: $HeaderResponsive500Marge;
                    font-size: 10px;
                    padding: 2px;
                    top: 5px;
                }
                .stat-elem {
                    img {
                        @include width-height(70px, 70px);
                        bottom: -40px;
                    }
                    p {        
                        &:first-child {
                            font-size: 15px;
                        }
            
                        &:last-child {
                            font-size: 15px;
                        }
                    }
                }
            }
            .card-info {
                margin-left: $HeaderResponsive500Marge;
            }
        }
        .profile-main {
            margin-top: 20px;
            &-menu {
                gap: 1em;
                margin-bottom: 5px;
            }
            .profile-block-wrapper {
                margin: 0 $HeaderResponsive500Marge;
            }

            .history-list {
                display: flex;
                flex-direction: column;
                gap: 10px;
    
                .filter-select {
                    font-size: 12px;
                    padding: 3px;
                    width: 80px;
                }
            }
        }
    }
}

@media screen and (max-width: $BreakPoint_400) {
    .profile-container {
        .profile-header {
            .stats-infos {
                @include width-height(90%, 60%);
            }
        }
    }
}