@use "./Variables.scss" as *;
@use "./Mixins.scss" as *;

$orangeColor: rgba(222, 153, 24, 0.974);

.match-found-container {
    z-index: 5000;
    position: absolute;
    @include width-height(350px, 350px);
    border-radius: 50%;
    background-color: $DarkPalette;
    border: 4px solid $orangeColor;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .outside-circle {
        position: absolute;
        @include width-height(410px, 410px);
        border: 1px solid rgba(255, 166, 0, 0.374);
        border-radius: 50%;
    }

    .found-title {
        color: aliceblue;
        font-size: 25px;
        font-weight: bold;
    }

    p {
        text-align: center;
        letter-spacing: 1px;
    }

    .accept-button {
        all: unset;
        cursor: pointer;
        bottom: -13px;
        position: absolute;
        padding: 10px 55px 15px 55px;
        background-color: $DarkPalette;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border: 1px solid $orangeColor;
        font-weight: bold;
        color: $orangeColor;

        &:hover {
            color: $DarkPalette;
            background-color: $orangeColor;
            border: 1px solid $DarkPalette;
        }
    }    
}