@use "./Variables.scss" as *;
@use "./Mixins.scss" as *;

.home-container {
    @include page-wrapper();
    background-color: $LightDarkPalette;
    font-family: Silkscreen;

    .home-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5em;

        h1 {
            font-size: 90px;
            text-align: center;
        }

        .home-button {
            all: unset;
            cursor: pointer;
            @include width-height(25em, 3.5em);
            background-color: $BlueDarkPalette;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition-duration: 0.5s;
        }
    
        .home-button:hover {
            transform: scale(1.2);
            transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        }
    }   
}

@media screen and (max-width: $BreakPoint_800) {
    .home-container {
        .home-menu {
            h1 {
                font-size: 70px;
            }
            .home-button {
                @include width-height(25em, 3.5em);
            }
        }
    }
}

@media screen and (max-width: $BreakPoint_500) {
    .home-container {
        .home-menu {
            h1 {
                font-size: 50px;
            }
            .home-button {
                @include width-height(20em, 3.5em);
            }
        }
    }
}

@media screen and (max-width: 330px) {
    .home-container {
        .home-menu {
            h1 {
                font-size: 35px;
            }
            .home-button {
                @include width-height(15em, 3.5em);
            }
        }
    }
}