@use "./Variables.scss" as *;

.chat-party-sidebar {
    position: absolute;
    background-color: $LightDarkPalette;
    width: 400px;
    right: 0;
    height: 100%;
    z-index: 10000;
    box-shadow: rgb(0, 0, 0) 0px 5px 15px;
}

@media screen and (max-width: $BreakPoint_500) {
    .chat-party-sidebar {
        width: 90%;
    }
}