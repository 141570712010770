@use "./Variables.scss" as *;
@use "./Mixins.scss" as *;

.sign-container {
    min-width: 100%;
    min-height: 100vh;
    overflow: auto;
    position: absolute;
    top: -$headerHeight;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    text-align: center;
    background: url('../Assets/Backgrounds/background-42.jpg') no-repeat center fixed;
    background-size: cover;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .auth-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin: auto;
        background-color: $LightDarkPalette;
        padding: 15px;
        width: 300px;
        // min-height: 380px;
        border-radius: 5px;

        .auth-separator {
            font-size: 16px;
            margin: 1em auto;
            position: relative;
            text-align: center;
            width: 100%;

            &::before {
                border-top: 1px solid white;
                content: " ";
                display: inline-block;
                position: absolute;
                top: .7em;
                width: 43%;
                left: 0;
            }

            &::after {
                border-top: 1px solid white;
                content: " ";
                display: inline-block;
                position: absolute;
                top: .7em;
                width: 43%;
                right: 0;
            }
        }

        .sign-42-button {
            all: unset;
            border: 1px solid white;
            border-radius: 3px;
            padding: 7px;
            cursor: pointer;
        }

        .validation-form-wrapper {
            display: flex;
            flex-direction: column;
            gap: 6px;
            // height: 320px;
            justify-content: space-between;
            .txt-verification {
                margin: 0;
            }
            .code-input {
                all: unset;
                border: 0.5px solid white;
                margin-top: 20px;
                letter-spacing: 15px;
                padding: 15px;
            }

            button {
                all: unset;
                border: none;
                cursor: pointer;
                margin-top: 10px;
                border-radius: 3px;
                height: 35px;
                background-color: $LightBluePalette;
            }
        }

        .form-wrapper {
            display: flex;
            flex-direction: column;
            gap: 6px;

            input {
                all: unset;
                border: 1px solid white;
                border-radius: 5px;
                padding: 4px;
            }

            button {
                all: unset;
                border: none;
                cursor: pointer;
                margin-top: 10px;
                border-radius: 3px;
                height: 35px;
                background-color: $LightBluePalette;

                
            }

            .forgot-password {
                font-size: 10px;
                margin: 0;
                text-align: end;
            }
        }
        .btn-switch-form {
            font-size: 12px;
            cursor: pointer;
        }
    }

    .username-form {
        display: flex;
        flex-direction: column;
        margin: auto;
        gap: 10px;
        input {
            all: unset;
            border: 1px solid white;
            border-radius: 5px;
            padding: 5px;
        }
    }

    .email-notification-wrapper {
        margin: auto;
        background-color: $LightDarkPalette;
        padding: 40px;
        border-radius: 10px;
        text-align: center;
        svg {
            @include width-height(130px, 130px);
        }
        h3 {
           font-size: 25px;
        }
        p {
            font-size: 15px;
            span {
                color: $OrangeProfile;
            }
        }
    }
}