@use '../Variables.scss' as *;
@use "../Mixins.scss" as *;

.chat-modal {
    @include modal-container(620px, 600px, $DarkPalette); // Params => Height, Width, Background-color
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;

    .search-player-container {
        .modal-player-list {
            height: 350px;
        }
    }

    .form-modal-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        h3 {
            text-align: center;
            margin-top: 1px;
        }
    
        form {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 10px;
            .checkbox-container {
                display: flex;
                gap: 5px;

                input[type="radio"] {
                    accent-color: $LightBlueButton;
                }
            }
            .labelTextInput {
                display: flex;
                flex-direction: column;
                gap: 5px;
                font-size: 15px;
                input {
                    all: unset;
                    cursor: text;
                    padding: 8px;
                    border: 1px solid $WhitePalette;
                    margin-top: 2px;
                    &::placeholder {
                        font-size: 12px;
                    }
                }
            }
    
            .chat-modal-buttons {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 10px;
                button, input {
                    @include blue-button(14px, 7px);
                    margin: 0;
        
                    &:first-child {
                        background-color: $DarkPalette;
                        color: rgb(200, 12, 12);
                        border: 1px solid rgb(200, 12, 12);

                        &:hover {
                            background-color: rgb(200, 12, 12);
                            color: $DarkPalette;
                        }
                    }
                }
            }
        }
    }    
}