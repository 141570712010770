$DarkPalette: #0D1B2A;
$LightDarkPalette: #1B263B;
$BlueDarkPalette: #415A77;
$LightBluePalette: #778DA9;
$WhitePalette: #E0E1DD;

$LightBlueButton: #87beff;

$OrangeProfile: orange;

$HeaderPartyBackground: #303030;
$HeaderPartyGrey: #565555;
$HeaderPartyGreyHover: #676767;

$HeaderMarge: 80px;
$HeaderResponsive800Marge: 40px;
$HeaderResponsive500Marge: 10px;

$ProfileBlockMarge: 30px $HeaderMarge;

$BreakPoint_800: 855px;
$BreakPoint_500: 575px;
$BreakPoint_400: 400px;

$headerHeight: 70px;

$OnlineColor: rgb(15, 187, 15);
$OfflineColor: rgb(194, 29, 29);

@font-face {
    font-family: Montserrat;
    src: url("../../public/Font/Montserrat/static/Montserrat-Regular.ttf");
    font-display: swap;
}

@font-face {
    font-family: Silkscreen;
    src: url("../../public/Font/Silkscreen/Silkscreen-Regular.ttf");
    font-display: swap;
}

@font-face {
    font-family: OpenSans;
    src: url("../../public/Font/Open_Sans/static/OpenSans/OpenSans-Light.ttf");
    font-display: swap;
}